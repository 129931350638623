import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {format} from 'date-fns';
import {useParams} from 'react-router-dom';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Checkbox from '@mui/material/Checkbox';
import {Box, Popover, Typography, useTheme} from '@mui/material';
import TableLoaderSkeleton from '../../LoadingView/TableLoaderSkeleton';
import SubmissionDetails from '../SubmissionDetails';
import DeleteWarningModal from '../../Builder/Modals/DeleteWarningModal';
import AddTag from '../AddTag/AddTag';
import StatusCell from './StatusCell';
import TagsList from './TagsList';
import AddToCampaign from '../AddToCampaign/AddToCampaign';
import TableCellViewBySubmissionType from './TableCellViewBySubmissionType';
import SubMissionDetailsHeader from '../SubMissionDetailsHeader';
import BasicDrawer from '../../../common/Drawer/BasicDrawer';
import GlobalEmptyPage from '../../../common/GlobalEmptyPage/GlobalEmptyPage';
import GlobalTablePagination from '../../../common/Pagination/GlobalTablePagination';
import {EmptySubmissionFormIcon} from '../../../common/Icons/EmptyDataIcons';
import CustomPopoverMenu, {
  CustomPopoverMenuButton,
  CustomPopoverMenuItem
} from '../../../common/PopoverMenu/CustomPopoverMenu';
import removeFalsyObjectProperty from '../../../helpers/utils/removeFalsyObjectProperty';
import {castArray} from '../../../helpers/utils/castArray';
import fbpIcons from '../../../helpers/fbpIcons/fbpIcons';
import {selectFormSubmissionData} from '../../../state/features/formSubmission/formSubmissionSelector';
import {MoreVert} from '@mui/icons-material';
import {
  deselectAllInboxForm,
  getFormSubmissionData,
  inboxFormDelete,
  selectedAllInboxForm,
  selectFormSubmission,
  toggleSelectedSingleInboxForm
} from '../../../state/features/formSubmission/formSubmissionSlice';

function EnhancedTableHead({onSelectAllClick, numSelected, rowCount, tableHeader = []}) {
  const theme = useTheme();

  return (
    <TableHead sx={{backgroundColor: theme.palette.action.hover}}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            sx={{
              '& .PrivateSwitchBase-input': {
                pointerEvents: 'auto !important'
              }
            }}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{'aria-label': 'select all desserts'}}
          />
        </TableCell>
        <TableCell align="left" padding={'none'} sx={{minWidth: '130px'}}>
          Submission Date
        </TableCell>
        <TableCell align="left" padding={'normal'} sx={{minWidth: '120px'}}>
          Status
        </TableCell>
        <TableCell align="left" padding={'normal'} sx={{minWidth: '165px'}}>
          Tags
        </TableCell>

        {tableHeader?.length > 0 &&
          tableHeader.map(column => (
            <TableCell key={column.id} align="left" padding={'normal'} sx={{minWidth: '165px'}}>
              {column.label}
            </TableCell>
          ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

const getIdValuePair = submissionList => {
  let resultObj = {};
  for (let submissionData of submissionList) {
    resultObj[submissionData.id] = submissionData;
  }

  return resultObj;
};

const TableViewBody = () => {
  const dispatch = useDispatch();
  const {formId} = useParams();

  let {
    data: formSubmissionList,
    tableHeader,
    isLoading,
    page,
    limit,
    count,
    renderId,
    selectedSubmission: {id} = {},
    selectedInboxFormIds,
    filters: {searchText, startDate, endDate} = {}
  } = useSelector(selectFormSubmissionData);
  tableHeader = Array.isArray(tableHeader) ? tableHeader.slice(0, 4) : [];

  const [anchorEl, setAnchorEl] = useState(null);

  const [isOpenSubmissionModal, setOpenSubmissionModal] = useState(false);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [selectedOpenMenuId, setSelectedOpenMenuId] = useState('');

  const toggleDrawerOpen = () => setDrawerOpen(prevState => !prevState);

  const onOpenSubmissionModal = () => setOpenSubmissionModal(true);
  const onCloseSubmissionModal = () => setOpenSubmissionModal(false);

  const onOpenDeleteModal = () => setOpenDeleteModal(true);
  const onCloseDeleteModal = () => setOpenDeleteModal(false);

  const handleViewSubmission = data => {
    dispatch(selectFormSubmission(data));
    onOpenSubmissionModal();
  };

  const handleAddToCampaign = data => {
    dispatch(selectFormSubmission(data));
    toggleDrawerOpen();
  };

  // ## handle add tag
  const handleOpen = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleAddTag = (event, data) => {
    handleOpen(event);
    dispatch(selectFormSubmission(data));
  };

  const handleDelete = data => {
    dispatch(selectFormSubmission(data));
    onOpenDeleteModal();
  };

  const handleDeleteConfirm = () => {
    dispatch(inboxFormDelete({ids: castArray(id)}));
    onCloseDeleteModal();
  };

  // ## handle toggle columns select all
  const handleSelectAllClick = event => {
    if (event.target.checked) {
      dispatch(selectedAllInboxForm());
    } else {
      dispatch(deselectAllInboxForm());
    }
  };

  // ## handle toggle columns select single
  const handleToggleSelect = (event, id) => {
    dispatch(toggleSelectedSingleInboxForm(id));
  };

  useEffect(() => {
    const reqObj = removeFalsyObjectProperty({
      formId: formId,
      page: 1,
      limit: limit,
      searchText,
      startDate,
      endDate
    });
    dispatch(getFormSubmissionData(reqObj));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, startDate, endDate, renderId]);

  // ## handle table pagination
  const handleChangePage = newPage => {
    const reqObj = removeFalsyObjectProperty({
      formId: formId,
      page: newPage,
      limit: limit,
      searchText,
      startDate,
      endDate
    });
    dispatch(getFormSubmissionData(reqObj));
  };

  const handleChangeRowsPerPage = (perPage, newPage) => {
    const reqObj = removeFalsyObjectProperty({
      formId: formId,
      page: newPage,
      limit: perPage,
      searchText,
      startDate,
      endDate
    });
    dispatch(getFormSubmissionData(reqObj));
  };

  if (isLoading) {
    return <TableLoaderSkeleton numberOfRows={10} columns={5} />;
  }
  const isSelected = id => selectedInboxFormIds.indexOf(id) !== -1;
  const open = Boolean(anchorEl);

  return (
    <>
      <Box sx={{height: 'calc(100% - 124px)'}}>
        <TableContainer className="fbp_has_scroll fbp_has_xscroll">
          <Table
            sx={{minWidth: 'max-content', width: '100%'}}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            stickyHeader
          >
            <EnhancedTableHead
              numSelected={selectedInboxFormIds.length}
              rowCount={formSubmissionList.length}
              onSelectAllClick={handleSelectAllClick}
              tableHeader={tableHeader}
            />

            <TableBody>
              {tableHeader?.length > 0 &&
                formSubmissionList.map((row, index) => {
                  const {
                    submission: {submission: submissionList = [], agencyId = ''},
                    tagIds = [],
                    contactId,
                    createdAt,
                    comments
                  } = row;
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  const submissionObject = getIdValuePair(submissionList);
                  const formatUpdatedAt = createdAt
                    ? format(new Date(createdAt), 'dd MMM yyyy')
                    : '';

                  const activeRow = isItemSelected || selectedOpenMenuId === row.id;
                  return (
                    <TableRow
                      hover={true}
                      key={row.id}
                      role="checkbox"
                      tabIndex={-1}
                      aria-checked={isItemSelected}
                      selected={activeRow}
                    >
                      <TableCell
                        padding="checkbox"
                        onClick={event => handleToggleSelect(event, row.id)}
                      >
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{'aria-labelledby': labelId}}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        <Typography variant={'body2'} color={'text.secondary'}>
                          {formatUpdatedAt}
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        <StatusCell comments={comments} />
                      </TableCell>

                      <TableCell align="left">
                        <Box sx={{maxWidth: '160px'}}>
                          <TagsList tagIds={tagIds} submissionId={row.id} />
                        </Box>
                      </TableCell>

                      {tableHeader.map(column => (
                        <TableCell key={column.id} align="left">
                          <TableCellViewBySubmissionType
                            column={submissionObject[column.id]}
                            agencyId={agencyId}
                            contactId={contactId}
                          />
                        </TableCell>
                      ))}

                      <TableCell align="left">
                        <CustomPopoverMenu
                          position="left"
                          callbackOnClose={() => setSelectedOpenMenuId('')}
                        >
                          <CustomPopoverMenuButton>
                            <MoreVert
                              color={'action'}
                              sx={{cursor: 'pointer'}}
                              onClick={() => setSelectedOpenMenuId(row.id)}
                            />
                          </CustomPopoverMenuButton>

                          <CustomPopoverMenuItem
                            title="View Submission"
                            icon={fbpIcons.fbpEyeIcon}
                            onClickHide={true}
                            onClick={() => handleViewSubmission(row)}
                          />
                          {contactId ? (
                            <CustomPopoverMenuItem
                              title="Add to Campaign"
                              icon={fbpIcons.fbpBullhornIcon}
                              onClickHide={true}
                              onClick={() => handleAddToCampaign(row)}
                            />
                          ) : (
                            ''
                          )}
                          <CustomPopoverMenuItem
                            title="Add Tags"
                            icon={fbpIcons.fbpLocalOfferIcon}
                            onClickHide={false}
                            onClick={event => handleAddTag(event, row)}
                          />
                          <CustomPopoverMenuItem
                            title="Delete"
                            icon={fbpIcons.fbpTrashIcon}
                            onClickHide={true}
                            onClick={() => handleDelete(row)}
                          />
                        </CustomPopoverMenu>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {formSubmissionList.length === 0 && (
            <div>
              <GlobalEmptyPage
                icon={<EmptySubmissionFormIcon />}
                title={'Inbox not submission'}
                description={
                  'All your submissions & reports data to this form will be gone forever. This operation cannot be undone.'
                }
              />
            </div>
          )}
        </TableContainer>

        {/*## Table Pagination View ##*/}
        <GlobalTablePagination
          count={count}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 15, 20, 30, 40, 50]}
          onChangePage={handleChangePage}
          onChangePerPage={handleChangeRowsPerPage}
        />

        {/*## All Actions View ##*/}
        <BasicDrawer
          header={<SubMissionDetailsHeader />}
          open={isOpenSubmissionModal}
          toggleDrawer={onCloseSubmissionModal}
        >
          <SubmissionDetails />
        </BasicDrawer>

        {/* ## tag Popover list ## */}
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <AddTag />
        </Popover>

        <BasicDrawer open={isDrawerOpen} title={'Add to Campaign'} toggleDrawer={toggleDrawerOpen}>
          <AddToCampaign onClose={toggleDrawerOpen} isBulkAction={false} />
        </BasicDrawer>

        <DeleteWarningModal
          title={'Delete Submission'}
          warningSubtitle={'Are you sure you want to delete selected submission(s)?'}
          warningContent={
            'Selected 1 submissions will be deleted permanently.This action cannot be undone.'
          }
          cancelButtonText={'No, Keep it'}
          buttonText={'Yes, Delete Forever'}
          open={isOpenDeleteModal}
          onClose={onCloseDeleteModal}
          onConfirm={handleDeleteConfirm}
        />
      </Box>
    </>
  );
};

export default TableViewBody;
