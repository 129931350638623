import React, {useState, useRef} from 'react';
import {Stack, Typography} from '@mui/material';
import CoreTextField from '../../../common/TextField/CoreTextField';
import CustomEditor from '../../../common/Editor/CustomEditor';
import {debounceHandler} from '../../../helpers/utils/debounceHandler';
import {CustomDropdown} from '../../../common/CustomDropdown';
import {USER_PERSONALIZED_MAP} from '../../../helpers/constant/CoreConstants';

const CommonTitleAndEditor = ({linkText = true, payload, onChangePayload}) => {
  const editorRef = useRef();

  const [formData, setFormData] = useState({
    linkText: payload.linkText,
    content: payload.content
  });

  const handleInsertPersonalized = e => {
    if (editorRef.current) {
      editorRef.current.insertContent(e.target.value);
    }
    setTimeout(() => {
      editorRef.current.focus();
    }, 500);
  };

  const doChangePayload = newPayload => {
    if (onChangePayload) onChangePayload(newPayload);
  };

  // debounce implement for search input change
  const handleChange = React.useMemo(() => {
    return debounceHandler(doChangePayload, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  return (
    <Stack spacing={2} p={2}>
      {linkText && (
        <Stack spacing={0.5}>
          <Typography variant={'body2'} color={'text.secondary'}>
            Link Text
          </Typography>
          <CoreTextField
            fullWidth
            type={'text'}
            size={'small'}
            color={'secondary'}
            name={'linkText'}
            inputProps={{
              maxLength: 40
            }}
            value={formData.linkText}
            onChange={e => {
              setFormData(prevState => ({...prevState, linkText: e.target.value}));
              return handleChange({...formData, linkText: e.target.value});
            }}
          />
        </Stack>
      )}

      <Stack spacing={0.5}>
        <Stack direction="rows" alignItems="center" justifyContent="space-between">
          <Typography variant={'body2'} color={'text.secondary'}>
            Edit here
          </Typography>
          <CustomDropdown
            width="200px"
            data={[{title: 'Select Personalize', value: ''}, ...USER_PERSONALIZED_MAP]}
            placeholder="Select Personalize"
            onChange={handleInsertPersonalized}
            wrapperClassName="formListFilter"
          />
        </Stack>
        <CustomEditor
          onInit={(evt, editor) => {
            if (editorRef) {
              editorRef.current = editor;
            }
          }}
          body={formData.content}
          onChangeBody={value => {
            setFormData(prevState => ({...prevState, content: value}));
            return handleChange({...formData, content: value});
          }}
        />
      </Stack>
    </Stack>
  );
};

export default CommonTitleAndEditor;
